@import "/src/styles/variables.scss";

.brand-modal {
  position: relative;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.input {
  margin-bottom: 12px;
}

.alphabet-list {
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 8px;
}

.alphabet-item {
  font-size: 1.2rem;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.selected {
  color: $brandAccent;
  text-decoration: underline;
}

.alphabet-item:hover {
  text-decoration: underline;
}

.brand-list {
  list-style: none;
  overflow-y: auto;
  padding-right: 1rem;
}

.brand-item {
  padding: 1rem;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.brand-item:hover {
  background: #f7f7f7;
}

@media screen and (max-width: $mobileBreakpoint) {
  .alphabet-list {
    display: none;
  }
}
