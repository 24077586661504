@import "styles/variables.scss";

.date-tools {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// .reset-date-container,
.date-changer,
.date-view {
  // flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.date-changer {
  // gap: 20px;

  span {
    // min-width: 200px;
    text-align: center;
  }
}

.date-view {
  gap: 5px;
  justify-content: flex-end;
  width: fit-content !important;
}

.date-tools-left {
  display: flex;
  align-items: center;
}

.shipping-list {
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-left: 40px;
  // width: 0px;
  // overflow: visible;
}

.shipping-list-label {
  font-size: 14px;
  line-height: 14px;
  margin-left: 5px;
}

.date-view-button,
.reset-date-container,
.navigate-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px;
  // &:hover,
  &:active {
    background-color: $lightGray;
  }
}

.date-view-button {
  height: 50px;
  width: 50px;
}

.reset-date-container {
  justify-content: flex-start;
  cursor: default;
  &:active {
    background-color: unset;
  }
}

.date-input {
  position: relative;
  width: 210px;
  display: none;
}

.reset-date {
  border-radius: 5px;
  // padding: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:active {
    background-color: $lightGray;
  }
}

.navigation-icon {
  width: 40px;
  height: 40px;
  margin: 0px 20px;
}

.date-string {
  cursor: pointer;

  &:active {
    color: $brandAccent;
  }
}

.navigate-button {
  border-radius: 50px;

  &:last-child {
    position: relative;
    order: 3;
  }
}

.toggled {
  background-color: $brandPrimary;
  color: $brandAccent;
  &:hover {
    background-color: $brandPrimary;
  }
}

@media screen and (max-width: $tabletBreakpoint) {
  .date-tools {
    flex-direction: column-reverse;
  }
}
