@import "styles/variables.scss";

.shipping-appointment {
  line-height: 2 !important;
  &:hover {
    filter: brightness(90%);
  }

  .VerticalAppointment-textContainer {
    color: red !important;
  }
}

.incoming,
.outgoing {
  div {
    font-size: 14px;
  }
}

.incoming {
  background-color: $success !important;
}

.outgoing {
  background-color: $info !important;
}

.incoming-shop {
  background-color: #43694c !important;
}

.outgoing-shop {
  background-color: #3b4f63 !important;
}

.incoming-draft {
  background-color: rgb(98, 116, 102) !important;
}

.outgoing-draft {
  background-color: rgb(95, 123, 150) !important;
}

.all-day {
  background-color: $brandAccent !important;
  div {
    &:nth-child(1) {
      font-size: 13.5px;
    }
    &:nth-child(2) {
      display: none;
    }
  }
}

.chronopost {
  background-color: $chronopost !important;
  div {
    &:nth-child(1) {
      font-size: 13.5px;
      font-weight: bold;
    }
  }
}
.month-canceled,
.month-chronopost,
.month-shipping {
  div {
    &:nth-child(1) {
      position: relative;
      top: -1.5px;
      font-size: 14px;
      font-weight: bold;
    }
  }
}

.month-canceled {
  background-color: $error !important;
}

.month-chronopost {
  background-color: $chronopost !important;
}

.month-shipping {
  background-color: $success !important;
}
